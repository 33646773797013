import Vue from "vue";
import axios from "axios";
import store from "../store/index.js";
var ax = axios;

const axiosx = axios.create({
    // baseURL: "http://rcb-console-api.test/api"
    baseURL: "https://console-api.recyclingbalers.com/api"
});

Vue.prototype.$axiosx = axiosx;
Vue.prototype.$axios = ax;

axiosx.interceptors.request.use(
    function (config) {
        store.commit("loading", true);
        config.headers.Authorization = `Bearer ${localStorage.getItem("admin-client")}`;
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

ax.interceptors.request.use(
    function (config) {
        // let token = localStorage.getItem('admin-client');
        // store.commit('loading', true);
        // config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    function (err) {
        return Promise.reject(err);
    }
);

function successResponse(res) {
    store.commit("loading", false);

    if (res.data && res.data.status && res.data.status == "redirect") {
        window.location = res.data.message;
        return false;
    }

    if (res.data && res.data.snackText) {
        var snackText = res.data.snackText;
    }

    if (snackText) {
        store.commit("snackbar", { status: true, text: snackText });
    }

    // Do something with response data
    return res;
}

function errorResponse(error) {
    store.commit("loading", false);

    var res = error.response;
    var errors = null;

    if (res.data.errors) {
        errors = res.data.errors.name;
    }

    if (res.status === 401) {
        window.location.href = "/login";
        return;
    }

    if (res.status === 422 || res.status === 405) {
        console.log(res);
        Object.values(res.data.errors).map((er) => {
            alert(er[0]);
        });
    }

    if (res.data.message) {
        alert(res.data.message);
    }

    if (errors && errors[0]) {
        store.commit("snackbar", {
            status: true,
            text: errors[0],
        });
    }
}

axiosx.interceptors.response.use(
    successResponse,
    errorResponse
);

ax.interceptors.response.use(
    function (response) {
        store.commit("loading", false);
        // Do something with response data
        return response;
    },
    function (response) {
        store.commit("loading", false);
        var res = error.response;
    }
);

export {
    axiosx
};

export default ax;
