<template>
    <div>
        <v-form id="pageAdd" ref="pageAdd" @submit.prevent="pageAdd" autocomplete="nope">
            <Header
              :heading="getHeading()"
            >
                
                <v-switch
                    hide-details
                    class="mr-8"
                    v-model="page.status"
                    inset
                    name="status"
                    label="Status"
                    color="success"
                    :value="page.status"
                ></v-switch>
              <v-btn
                  min-width="130px"
                  color="success"
                  class="mr-5"
                  type="submit"
                  :loading="$store.state.loading"
              >
                  <v-icon left>mdi-content-save-outline</v-icon>
                  Save
              </v-btn>
              <v-btn
                  to="/pages"
                  color="info"
              >
                  <v-icon left>mdi-view-list</v-icon>
                  list
              </v-btn>
            </Header>
            <v-layout class="row wrap">
                <v-flex class="col sm12 xs12 md9 xl9">
                    <v-card class="full">
                         <v-tabs
                           background-color="white"
                           color="info accent-4"
                           left
                         >
                             <v-tab>Information</v-tab>
                             <v-tab>SEO</v-tab>
                             <v-tab-item class="pa-5" :eager="true">
                               <v-text-field
                                 v-model="page.name"
                                 label="Name*"
                                 name="name"
                                 required
                                 :rules="validateRules"
                                 autocomplete="nope"
                               ></v-text-field>

                               <v-text-field
                                 v-model="page.url"
                                 label="Url*"
                                 type="text"
                                 required
                                 :rules="validateRules"
                                 name="url"
                                 autocomplete="nope"
                               ></v-text-field>

                               <v-autocomplete
                                 :items="states"
                                 item-value="id"
                                 item-text="name"
                                 v-model="page.id_state"
                                 label="Choose State"
                               ></v-autocomplete>
                               <input type="hidden" name="id_state" :value="page.id_state" />

                               <v-select
                                 :items="templates"
                                 v-model="page.template"
                                 label="Choose Template"
                               ></v-select>
                               <input type="hidden" name="template" :value="page.template" />

                               <v-text-field
                                 v-if="page.template == 'city'"
                                 v-model="page.heading1"
                                 label="Heading 1*"
                                 type="text"
                                 :rules="validateRules"
                                 name="heading1"
                                 autocomplete="nope"
                               ></v-text-field>

                               <v-text-field
                                 v-if="page.template == 'city'"
                                 v-model="page.heading2"
                                 label="Heading 2*"
                                 type="text"
                                 :rules="validateRules"
                                 name="heading2"
                                 autocomplete="nope"
                               ></v-text-field>

                               <v-text-field
                                 v-model="page.body_class"
                                 label="Body Class"
                                 type="text"
                                 name="body_class"
                                 autocomplete="nope"
                               ></v-text-field>

                               <Textarea
                                   autocomplete="nope"
                                   outlined
                                   name="content"
                                   label="Content"
                                   :value="page.content"
                               ></Textarea>

                             </v-tab-item>

                          <v-tab-item class="pa-5" :eager="true">
                            <SEO
                              :seo="page"
                            >
                            </SEO>
                          </v-tab-item>
                        </v-tabs>
                    </v-card>
                </v-flex>

                <v-flex class="col sm12 xs12 md3 xl3">
                    <div justify="center">
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                        >

                            <v-expansion-panel class="_collapse">
                                <v-expansion-panel-header>Hero Image</v-expansion-panel-header>
                                <v-expansion-panel-content :eager="true">
                                  <File
                                      block
                                      cls="_block"
                                      :value="page.hero_image"
                                      text="Choose Image"
                                      name="id_hero_image"
                                      :multiple="false"
                                  ></File>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                              <v-expansion-panel-header>Hero Video</v-expansion-panel-header>
                              <v-expansion-panel-content :eager="true">
                                <File
                                    block
                                    cls="_block"
                                    :value="page.hero_video"
                                    text="Choose Video"
                                    name="id_hero_video"
                                    type="video"
                                    :multiple="false"
                                ></File>
                              </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </div>
                </v-flex>
            </v-layout>
      </v-form>
    </div>
</template>
<script>
  export default {
    beforeCreate() {
        var url = '/page/add';

        if (this.$router.history.current.params && this.$router.history.current.params.id) {
          url = '/page/edit/' + this.$router.history.current.params.id;
        }

        this.$axiosx.get(url)
        .then((res) => {
          this.page = res.data.page;
        });

    },
    created () {
      this.getStates();
    },
    data () {
      return {
        templates: ['page', 'city'],
        states: null,
        panel: 0,
        page: {
          template: 'page'
        },
        validateRules: [
          v => !!v || 'This field is required'
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ]
      }
    },
    watch : {
      '$route.query.added' : function (val) {
          this.getData();
      }
    },
    methods: {
        getStates () {
            this.$axiosx.get('/states')
            .then((res) => {

              this.states = res.data;

            }).catch((e) => {



            });
        },
        getData () {
          if (this.$route.params && this.$route.params.id) {
                var url = '/page/edit/' + this.$route.params.id;
                return this.$axiosx.get(url)
                .then((res) => {
                    this.page = res.data.page;
                });
            }
        },
        getHeading () {
            if (this.page && this.page.name) {
                  return 'Page: ' + this.page.name;
            }

            return 'Add Page';
        },
      pageAdd () {
          if (this.$refs.pageAdd.validate() == false) {
              this.$store.commit('snackbar', {
                status: 'error',
                text: 'Please supply mandatory fields.'
              });
              return true;
          }
        var fd = new FormData(this.$refs.pageAdd.$el);
        this.dialog = true;
        var url = '/page/add';
        if (this.$router.history.current.params && this.$router.history.current.params.id) {
          url = '/page/edit/' + this.$router.history.current.params.id;
        }
        this.$axiosx.post(url, fd).then((res) => {
          if (res.data.status == 'error') {
            this.$store.commit('snackbar', res.data);
          }

          if (res.data.status == 'redirect') {
            this.$router.push({
              path: res.data.text,
              query: { added: 'true' }
            });
            this.added();
          }
          if (res.data.status == 'success') {
              this.$store.commit('snackbar', res.data);
          }
        });
      },
      added() {
        if (this.$router.history.current.query.added == 'true') {
          this.$store.commit('snackbar', {
            status: 'success',
            text: 'Page Added Successfully'
          });
        }
      }
    }
  }
</script>
