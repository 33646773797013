<template>
	<v-container class="flex justify-center align-center _100vh">
		<v-card width="500">
			<v-card-text>
				<h2 class="mb-5">Login to Console</h2>
				<v-form ref="loginForm" @submit.prevent="logIn">
					<v-text-field
						label="E-Mail Address*"
						required
						v-model="email"
						:rules="validateRules"
						type="email"
					>
					</v-text-field>
					<v-text-field
						type="password"
						label="Password*"
						:rules="validateRules"
						required
						v-model="password"
					>
					</v-text-field>

					<v-checkbox
						label="Keep me logged in"
						v-model="keep_active"
						color="success"
					></v-checkbox>

					<v-btn
						type="submit"
						large
						class="mt-3 mb-3"
						color="info"
						:loading="loading"
						>Log In</v-btn
					>
				</v-form>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			email: null,
			password: null,
			loading: false,
			keep_active: false,
			validateRules: [(v) => !!v || "This field is required"],
		};
	},
	beforeCreate() {
		this.$store.commit("setDrawerVisibility", false);
	},
	methods: {
		logIn() {
			if (!this.$refs.loginForm.validate()) {
				this.$store.commit("snackbar", {
					status: "error",
					text: "Please supply mandatory fields.",
				});
				return true;
			}

			this.loading = true;

			var fd = new FormData();
			fd.append("email", this.email);
			fd.append("password", this.password);
			fd.append("keep_active", this.keep_active);

			this.$axiosx
				.post("/user/login", fd)
				.then((res) => {
					this.loading = false;

					if (res.data.status == "success") {
						localStorage.setItem("admin-client", res.data.token);
						this.$store.commit("setDrawerVisibility", true);

						if (res.data.user_id === 5) {
							window.location.href = "/console/app/post";
						} else {
							window.location.href =
								"/console/app/equipment/list/used";
						}

						return true;
					}

					this.$store.commit("snackbar", {
						status: "error",
						text: res.data.message,
					});
				})
				.catch((e) => {
					this.loading = false;

					var res = e.response;
					console.log(res);
				});
		},
	},
};
</script>
