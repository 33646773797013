<template>
    <div>
        <Header
          heading="Leads"
        >
        </Header>

        <Leads></Leads>
    </div>
</template>

<script>
export default {
    components: {
        Leads: () => import('../components/LeadsList')
    }
}
</script>

<style lang="css" scoped>
</style>
