import Vue from "vue";
import vuetify from "./plugins/Vuetify.js";
import axios from "./plugins/Axios.js";
import Index from "./pages/Index";
import Routes from "./routes.js";
import vuex from "./store/index.js";

Vue.config.productionTip = false

new Vue({
    vuex,
    axios,
    vuetify,
    router: Routes,
    render: h => h(Index),
}).$mount('#app')
