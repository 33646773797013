<template>
	<v-navigation-drawer expand-on-hover height="auto" permanent id="_mainDrawer">
		<template v-slot:prepend>
			<v-list class="py-0" id="_logo">
				<v-list-item link two-line>
					<v-list-item-content>
						<v-list-item-title class="title _900"
							>Recyclingbalers</v-list-item-title
						>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>

		<v-divider></v-divider>

		<v-list nav id="_sideList" dense>
			<template v-for="item in items">
				<v-row v-if="item.heading" :key="item.heading" align="center">
					<v-col cols="6">
						<v-subheader v-if="item.heading">
							{{ item.heading }}
						</v-subheader>
					</v-col>
					<v-col cols="6" class="text-center">
						<a href="#!" class="body-2 black--text">EDIT</a>
					</v-col>
				</v-row>
				<v-list-group
					v-else-if="item.children"
					:key="item.text"
					v-model="item.model"
					:prepend-icon="item.icons"
					:append-icon="item.model ? arrowUp : arrowDown"
				>
					<template v-slot:activator>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									{{ item.text }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</template>
					<v-list-item
						v-for="(child, i) in item.children"
						:key="i"
						link
						color="red"
						:to="child.link"
					>
						<v-list-item-action v-if="child.icon">
							<v-icon>{{ child.icon }}</v-icon>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
								{{ child.text }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
				<v-list-item
					v-else
					:key="item.text"
					link
					color="red"
					:to="item.link"
				>
					<v-list-item-action>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>
							{{ item.text }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
export default {
	mounted() {
		this.setItems();
	},
	data() {
		return {
			arrowDown: "mdi-chevron-down",
			arrowUp: "mdi-chevron-up",
			items: [],
		};
	},
	methods: {
		setItems() {
			if (!this.$store.state.user) {
				return [];
			}

			if (this.$store.state.user.id === "5") {
				this.items = [
					{
						icons: "mdi-blogger",
						text: "Blog",
						model: false,
						children: [
							{
								icon: "mdi-post",
								text: "Post Category",
								link: "/post/category",
							},
							{
								icon: "mdi-blogger",
								text: "Post",
								link: "/post",
							},
						],
					},
				];

				return;
			}

			this.items = [
				{
					icons: "mdi-iframe-braces-outline",
					text: "Equipment",
					model: false,
					children: [
						{
							icon: "mdi-view-list",
							text: "New Equipment",
							link: "/equipment/list/new",
						},
						{
							icon: "mdi-view-list",
							text: "Used Equipment",
							link: "/equipment/list/used",
						},
						{
							icon: "mdi-format-list-bulleted-type",
							text: "Equipment Type",
							link: "/equipment/type",
						},
						{
							icon: "mdi-shape-outline",
							text: "Equipment Category",
							link: "/equipment/category",
						},
						{
							icon: "mdi-settings-outline",
							text: "Equipment Options",
							link: "/equipment/options",
						},
					],
				},
				{
					icons: "mdi-account-convert",
					text: "Dealers",
					model: false,
					children: [
						{
							icon: "mdi-view-list",
							text: "Dealers",
							link: "/dealers",
						},
						{
							icon: "mdi-view-list",
							text: "Preferred Dealers",
							link: "/preferred/dealers",
						},
					],
				},
				{
					icons: "mdi-blogger",
					text: "Blog",
					model: false,
					children: [
						{
							icon: "mdi-post",
							text: "Post Category",
							link: "/post/category",
						},
						{ icon: "mdi-blogger", text: "Post", link: "/post" },
					],
				},
				{ icon: "mdi-comment-quote", text: "Leads", link: "/leads" },
				{
					icon: "mdi-account-tie-outline",
					text: "Customers",
					link: "/customers",
				},
				{
					icon: "mdi-auto-fix",
					text: "Completed Projects",
					link: "/completed/projects",
				},
				{
					icon: "mdi-face-agent",
					text: "Trusted Customers",
					link: "/trusted/customers",
				},
				{
					icon: "mdi-account-multiple-outline",
					text: "Partners",
					link: "/partners",
				},
				{
					icon: "mdi-cogs",
					text: "Manufacturers",
					link: "/manufacturers",
				},
				{
					icon: "mdi-book-open-page-variant",
					text: "Page",
					link: "/pages",
				},
				{ icon: "mdi-currency-usd", text: "Plans", link: "/plans" },
				{
					icon: "mdi-camera-metering-spot",
					text: "Block",
					link: "/block",
				},
				{
					icon: "mdi-television-classic",
					text: "Advertisement",
					link: "/advertisement",
				},
				{
					icon: "mdi-account-circle-outline",
					text: "Employee",
					link: "/employee",
				},
			];
		},
	},
};
</script>
