var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-dialog',{attrs:{"id":"_media","width":"100%","max-width":"1300px"},model:{value:(_vm.media_dialog),callback:function ($$v) {_vm.media_dialog=$$v},expression:"media_dialog"}},[_c('v-card',{attrs:{"max-height":"800","min-height":"700","color":"#f9f9f9","tile":"","id":"_mediaCard","loading":_vm.loading}},[_c('v-tabs',{attrs:{"color":"info","left":"","id":"_mediaTabs"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-tab',[_vm._v("Media")]),_c('v-tab',[_vm._v("Upload")]),_c('v-tab-item',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":"","id":"_mainContainer"}},[_c('div',{attrs:{"id":"innerWrap"}},_vm._l((_vm.media),function(m,i){return _c('div',{key:i,class:'_image ' + _vm.getClass(m),on:{"click":function($event){return _vm.chosen(m)}}},[(m.url && m.type == 'image')?_c('img',{attrs:{"src":m.url}}):_vm._e(),(
                                        m.url &&
                                        (m.format == 'application/pdf' ||
                                            m.format == 'pdf')
                                    )?_c('span',{staticClass:"_pdf"},[_c('v-icon',[_vm._v("mdi-file-pdf-box")]),_vm._v(" "+_vm._s(m.name)+" ")],1):_vm._e(),(
                                        m.url &&
                                        m.type == 'video' &&
                                        m.format != 'embeded'
                                    )?_c('video',[_c('source',{attrs:{"src":m.url}})]):_vm._e(),(
                                        m.url &&
                                        m.type == 'video' &&
                                        m.format == 'embeded'
                                    )?_c('div',{domProps:{"innerHTML":_vm._s(m.name)}}):_vm._e(),_c('div',{staticClass:"_layer"})])}),0)])],1),_c('v-tab-item',{attrs:{"eager":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{attrs:{"id":"imageUploader"}})])],1)],1),(_vm.active == 0)?_c('div',{attrs:{"id":"_mediaFooter"}},[_c('div',{staticClass:"flex space-between"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"_paginate"},[(
                                    _vm.mediaData &&
                                    _vm.mediaData.last_page &&
                                    _vm.mediaData.last_page > 1
                                )?_c('v-select',{attrs:{"dense":"","solo":"","items":_vm.getPaginate(_vm.mediaData.last_page),"hide-details":""},model:{value:(_vm.paginate),callback:function ($$v) {_vm.paginate=$$v},expression:"paginate"}}):_vm._e()],1),_c('div',{staticClass:"_type ml-5"},[_c('v-select',{attrs:{"solo":"","items":_vm.types,"hide-details":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)]),_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.finishMediaSelection}},[_vm._v(" Finish ")])],1)]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }